import React, { useRef, useState, useEffect, Fragment } from "react";
import { Link } from "gatsby";
//import ParagraphZebraCard from "./zebra-card";

const ParagraphZebraHP = ({
  data,
  context,
  className,
  classNameCards,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const last = data.relationships.cards.length - 1
  const onChange = (index, dir) => {
    //console.log(index,dir)
    if (dir === "appear") {
      setCurrentIndex(parseInt(index));
    }

    if (dir === "disappear") {
      if( index !== last){
      setCurrentIndex(parseInt(index + 1));
      }
    }
  };

  return (
    <div className={className}>
      {data?.title?.processed && (
        <h2 className="text-center text-[48px] font-medium leading-[60px] tracking-[-0.05em] max-w-[1000px] mx-auto">
          {data.title.processed}
        </h2>
      )}
      {data?.description?.processed && <div>{data.description.processed}</div>}

      <div className="md:hidden grid grid-cols-1 gap-4">
        {data.relationships.cards.map((c, index) => (
          <Fragment key={index}>
            <CardBSimple
              data={c}
              key={"B" + index}
              context={context}
              index={index}
              current={index === currentIndex}
            />

            <CardSimple
              data={c}
              key={"A" + index}
              context={context}
              index={index}
              onChange={onChange}
              current={index === currentIndex}
            />
          </Fragment>
        ))}
      </div>
      <div
        className={
          "hidden md:grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-[80px] " +
          classNameCards
        }
      >
        {data.relationships.cards.map((c, index) => (
          <Fragment key={index}>
            <CardB
              data={c}
              key={"B" + index}
              context={context}
              index={index}
              current={index === currentIndex}
            />
            {index > 0 && <div className="min-h-[570px]"></div>}
            
            <Card
              data={c}
              key={"A" + index}
              context={context}
              index={index}
              onChange={onChange}
              current={index === currentIndex}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ParagraphZebraHP;

const CardB = ({ data, context, current, ...props }) => {
  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div
      className={
        "grid sticky  min-h-[570px] transition-opacity duration-300 --delay-100	 m-0 hidden md:block bg-[#4A72FF] rounded-[16px] " +
        (current ? "opacity-100 " : "opacity-0 ")
      }
      //ref={imageRef}
      style={{
        gridColumn: 1,
        gridRow: 1,
        placeItems: "center",
        top: 'calc(50vh - 285px)'
      }}
    >
      {image.src && (
        <div className="max-h-[570px] max-w-[570px]">
          <img src={`${image.src}`} alt={`${image.alt}`} />
        </div>
      )}
    </div>
  );
};

const Card = ({ data, context, onChange, ...props }) => {
  const { index, current } = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const elementRef = useRef(null);
  //const threshold = 300;

  const [stickyPosition, setStickyPosition] = useState(0);



  useEffect(() => {

  const calculateStickyPosition = () => {
    const vh = window.innerHeight / 100;
    const newStickyPosition = vh * 50 - 285 + 75;
    setStickyPosition(newStickyPosition);
  };
    calculateStickyPosition();
    window.addEventListener('resize', calculateStickyPosition);
    return () => {
      window.removeEventListener('resize', calculateStickyPosition);
    };
  }, []);



  useEffect(() => {
    const handleScroll = () => {
      const box = elementRef.current.getBoundingClientRect();
      if (scrollPosition && elementRef.current) {
        if (scrollPosition - box.bottom > 0) {
          // scrolling down
          if (scrollPosition >= stickyPosition && box.bottom <= stickyPosition) {
            onChange(index, "disappear");
          }
        } else {
          // scrolling up
          if (scrollPosition <= stickyPosition && box.bottom >= stickyPosition) {
            onChange(index, "appear");
          }
        }
      }
      setScrollPosition(box.bottom);
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [index, onChange, scrollPosition, stickyPosition]);

  const description = data?.description?.processed;

  return (
    <div
      className={
        "hidden md:flex flex-row items-center transition-opacity duration-300 h-[570px] --delay-100 px-4 md:px-0 " +
        (current ? "opacity-100" : "opacity-0")
      }
    >
      <div
        ref={elementRef}
        className="my-6 md:my-0 space-y-6 lg:space-y-[32px]  flex flex-col items-start  justify-center"
      >
        <h3
          className="font-medium text-[32px] md:text-[36px] leading-[36px] md:leading-[40px] tracking-[-0.03em] text-[#3D434C] "
          dangerouslySetInnerHTML={{
            __html: data.title.replace("  ", "<br/>"),
          }}
        />
        <div className="space-y-3 lg:space-y-[16px]">
          <div
            className="text-lg leading-[24px] md:leading-[27px] tracking-[-0.01em] text-[#3D434C] space-y-5"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {data.links.length > 0 && <Links links={data.links} />}
        </div>
      </div>
    </div>
  );
};

const Links = ({ links }) => {
  //console.log( links )
  return (
    <div className="">
      {links.map((item, i) => (
        <Link
          to={item.uri.replace("internal:/", "/")}
          key={i}
          className="text-lg font-medium text-[#3D434C]"
        >
          {item.title}
        </Link>
      ))}
    </div>
  );
};

const CardBSimple = ({ data, context, current, ...props }) => {
  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div className={" mx-4 transition-opacity duration-300 --delay-100 m-0"}>
      {image.src && (
        <div className="">
          <img src={`${image.src}`} alt={`${image.alt}`} />
        </div>
      )}
    </div>
  );
};

const CardSimple = ({ data, context, onChange, ...props }) => {
  const description = data?.description?.processed;

  return (
    <div
      className={
        "md:hidden my-6 md:my-0 space-y-6 lg:space-y-[32px]  flex flex-col --items-center justify-center transition-opacity	duration-300 --delay-100 px-4 md:px-0 "
      }
    >
      <h3
        className="font-medium text-[32px] md:text-[36px] leading-[36px] md:leading-[40px] tracking-[-0.03em] text-[#3D434C] "
        dangerouslySetInnerHTML={{
          __html: data.title.replace("  ", "<br/>"),
        }}
      />
      <div className="space-y-3 lg:space-y-[16px]">
        <div
          className="text-lg leading-[24px] md:leading-[27px] tracking-[-0.01em] text-[#3D434C] space-y-5"
          dangerouslySetInnerHTML={{ __html: description }}
          //ref={elementRef}
        />
        {data.links.length > 0 && <Links links={data.links} />}
      </div>
    </div>
  );
};
