import React, {useEffect, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";

const Appear = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const onChange = (flag) => {
    if (!isVisible) {
      setIsVisible(flag);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div
        className={isVisible ? " opacity-100 " : " opacity-0 "}
        style={{
          transition: "all",
          //transitionProperty:"all",
          transform: isVisible ? "translateY(0)" : "translateY(100px)",
          transitionDuration: "1000ms",
          transitionDelay: `${delay * 200}ms`,
        }}
      >
        <div className=" ">{children}</div>
      </div>
    </VisibilitySensor>
  );
};


const AppearV2 = ({ children, delay = 0 }) => {


  useEffect(() => {

    const slideInElements = document.querySelectorAll('.slidein');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');   
    
          observer.unobserve(entry.target);   
     // Optional: Stop observing after animation starts
        }
      });
    });
    
    slideInElements.forEach(element => {
      observer.observe(element);
    });
    

  },[])


  // const [isVisible, setIsVisible] = useState(false);
  // const onChange = (flag) => {
  //   if (!isVisible) {
  //     setIsVisible(flag);
  //   }
  // };

  return (
    <div />
  );
};


export default Appear;
export { AppearV2 }
