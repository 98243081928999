import React from "react";
import { Link } from "gatsby";
import TryOut from "../buttons/tryout";

const ParagraphHeroV2 = ({ data, context, onClick, children, ...props }) => {
  //console.log(data);
  //console.log(context);

  const { title, buttons } = data;
  const description = data?.description?.processed;
  const { className } = props;

  return (
    <div className={className}>
      <div className="space-y-8 md:space-y-[60px]">
        <div className="space-y-8 md:space-y-5">
          <h1
            className="text-center text-[32px] sm:text-[40px] lg:text-[72px] xl:text-[84px] font-semibold leading-[1.3] sm:leading-[1.2] lg:leading-[1.3] lg:tracking-[-0.03em] text-[#3D434C] max-w-[340px] sm:max-w-[500px] lg:max-w-[900px] xl:max-w-[1200px] mx-auto px-4 whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: title.replace("  ", "<br/>") }}
          />

          <div
            className="text-center text-xl md:text-lg leading-[32px] md:leading-[28px] text-[#3D434C] max-w-[940px] mx-auto"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="space-y-5">
          <div className=" flex flex-row justify-center items-center gap-2.5 md:gap-4">
            {buttons && buttons.map((item, index) => (
              <Button key={index} index={index} {...item} />
            ))}
          </div>
          <div>{children} </div>
        </div>
      </div>
    </div>
  );
};

const Button = ({ title, index, uri, ...props }) => {
  const url = uri.replace("internal:/", "/");

  if (index === 0) {
    return (
      <TryOut
        title={title}
        className="text-base  font-medium py-3 px-[30px] rounded-lg leading-[20px] whitespace-nowrap dark:hidden bg-[#325FFF] hover:bg-[#1B4DFF]  text-white border border-[#4A72FF] hover:border-[#3D434C88] demo_account try_it_free_hero "
      //   style={{
      //     boxShadow:
      //     "0px 0.14px 2.29px 0px #1A1D2108, 0px 0.37px 4.43px 0px #1A1D210C, 0px 3px 7px 0px #1A1D2117",
      //    }
      // }
        check={false}
      />
    );
  }

  // if (index > 1) {
  //   return (
  //     <Link
  //       to={url}
  //       className="border  rounded-lg px-[30px] py-3 font-medium leading-[20px]  text-white"
  //       style={{
  //         boxShadow:
  //           "0px 0.14px 2.29px 0px #1A1D2108, 0px 0.37px 4.43px 0px #1A1D210C, 0px 3px 7px 0px #1A1D2117",
  //         background:
  //           "linear-gradient(286.05deg, #4A72FF 6.93%, #1B4DFF 67.1%)",
  //       }}
  //     >
  //       {title}
  //     </Link>
  //   );
  // }

  return (
    <Link
      to={url}
      className="border  rounded-lg px-[30px] py-3 font-medium leading-[20px]  text-[#2D3643] bg-white  --hover:bg-[#F7F9FA] hover:bg-[#F0F3F9]"
      style={{
        boxShadow:
          "0px 0.14px 2.29px 0px #1A1D2108, 0px 0.37px 4.43px 0px #1A1D210C, 0px 3px 7px 0px #1A1D2117",
      }}
    >
      {title}
    </Link>
  );
};

export default ParagraphHeroV2;
