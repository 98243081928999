import React from "react";
import { Link } from "gatsby";
import Appear from "../appear";

const ParagraphGenericBlockAi = ({ data, ...props }) => {
  //console.log(data);
  //const { children } = props;

  const { /*title, description,*/ description_html, cta, label } = data;
  const image = {
    src: data?.relationships?.image?.localFile?.publicURL,
    alt: data?.image?.alt,
  };

  const image2 = {
    src: data?.relationships?.background?.localFile?.publicURL,
    alt: "background",
  };

  return (
    <div
      className="  flex flex-row justify-between items-center text-[#3D434C] rounded-[20px] relative bg-center bg-no-repeat	"
      style={{ backgroundImage: "url('/background/Homepage-AI-Background.png')"}}

    >
      <div className="py-6 md:py-[80px]  px-4 md:px-[50px]   space-y-[25px] md:h-[515px] md:flex flex-col justify-center">
        <div className="space-y-2.5">
          <div className="text-white text-[28px] leading-[34px] font-medium">
            {label}
          </div>
         
          <img src={image.src} alt={image.alt} />
        </div>

        {description_html && (
          <div
            className=" text-lg  leading-[27px] lg:max-w-[666px] text-white"
            dangerouslySetInnerHTML={{ __html: description_html.processed }}
          />
        )}

        <div>
          <Link
            className=" text-[#4A72FF] font-medium leading-[20px] inline-block "
            to={cta.uri}
          >
            {cta.title} {"-->"}
          </Link>
        </div>

        
      </div>

      <div className=" absolute right-[60px] hidden lg:block ">
        <Appear delay={1}>
          <div className="w-[500px] h-[610px]">
            <img src={image2.src} alt={image2.alt} className="z-10 block" />
          </div>
        </Appear>
      </div>
      
    </div>
  );
};



export default ParagraphGenericBlockAi;
