import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import ParagraphHeroV2 from "../components/paragraphs/hero-v2";
import ParagraphCardsContainer from "../components/paragraphs/cards-container";
import ParagraphZebraHP from "../components/paragraphs/zebra-hp";
import ParagraphTemplateTabsContainer from "../components/paragraphs/template-tabs-container";
import ParagraphCardsContainerHpHero from "../components/paragraphs/cards-container-hphero";
import ParagraphGenericBlockAi from "../components/paragraphs/generic-block-ai";
import BlockContentLogos from "../components/blocks/content-logos";
import BlockContentTestimonial from "../components/blocks/content-testimonial";
import BlockContentLogosSlider from "../components/blocks/content-logos-slider";

const IndexPage = ({ data: { node } }) => {
  //  console.log(node);

  const { relationships } = node;

  const {
    cards1,
    cards2,
    field_carousel_logos,
    field_integration_logos,
    hero,
    zebra,
    single,
    templates,
    testimonials,
  } = relationships;
  //let hero = relationships.hero;
  //hero.cta2 = cta2;

  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  return (
    <Layout isFrontPage={true} showCta={false} /*isExperimentalPage={true}*/>
      <Seo {...seo} />

      <section
        className="bg-top bg-no-repeat  bg-contain	"
        style={{
          backgroundImage: "url(/background/home-hero-background-2.png)",
        }}
      >
        <div className="max-w-screen-xl mx-auto px-4 lg:px-0 pt-[90px] md:pt-[150px] pb-[90px] ">
          <ParagraphHeroV2
            data={hero}
            context="frontpage"
            className="pb-[44px]"
          >
            <div className="text-[#727C8D] text-sm leading-[14px] text-center">
              {"Free 14 day trial. No credit card required."}
            </div>
          </ParagraphHeroV2>

          <ParagraphCardsContainerHpHero
            data={cards1}
            context="hp"
            className=""
          />

          <BlockContentLogosSlider
            data={field_carousel_logos}
            className="my-10 md:mt-[90px] md:mb-[10px] space-y-5"
            //context="slider"
          />
        </div>
      </section>

      <section className="bg-[#F9F8F7] py-8 lg:pt-[120px] lg:pb-[200px]">
        <div className="max-w-screen-xl mx-auto px-4 xl:px-0 space-y-4 lg:space-y-[100px]">
          <h2 className="text-[#3D434C] text-[32px] md:text-[72px] leadin-[40px] md:leadin-[80px] tracking-[-0.03em] text-center">
            {zebra.title}
          </h2>
          <ParagraphZebraHP
            data={zebra}
            className=" space-y-20"
            classNameCards="--space-y-4 --lg:space-y-[100px] "
          />
        </div>
      </section>

      <section className="bg-[#282D33] ">
        <div className="container mx-auto px-6 lg:px-0 ">
          <ParagraphGenericBlockAi data={single} context="ai" className="" />
        </div>
      </section>

      <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
        <ParagraphTemplateTabsContainer
          data={templates}
          className="py-12 md:pb-[120px] md:pt-[180px] space-y-10"
        />
      </div>

      <section className="py-8 md:py-[70px] bg-[#F9F8F7]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentLogos
            data={field_integration_logos}
            context="section"
            className="space-y-8 md:space-y-[50px]"
          />
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <ParagraphCardsContainer
            data={cards2}
            context="frontpage-bottom"
            classNameCards="flex flex-col lg:flex-row justify-between items-top gap-6 md:gap-5 "
            className="space-y-10 md:space-y-[70px] py-16 md:pt-[140px] md:pb-[60px]"
          />
        </div>
      </section>

      <section className="py-[70px]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentTestimonial data={testimonials} />
        </div>
      </section>

      <section className="bg-[#1A1D21] text-white py-6 md:py-10">
        <div className="max-w-screen-xl px-4 md:px-0 mx-auto  flex flex-row items-center justify-between text-[20px] md:text-[32px] font-normal leading-[24px] md:leading-[35px] tracking-[-0.03em]">
          <h2 className="">{"Get started with Sightfull"}</h2>

          <Link to={"/book-a-demo/"} className="block px-4">
            {"Book a Demo"} <span className="tracking-normal">{"-->"}</span>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query homepage2024 {
    node: nodeHomepage2024(id: { eq: "31e5bae5-f2e6-5ffa-b09f-6d6eaa67896a" }) {
      ...NodeHomepage2024
    }
  }
`;
