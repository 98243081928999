import React, { useState, useEffect } from "react";

const ParagraphCardsContainerHpHero = ({
  data,
  context,
  className,
  classNameCards,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const last = data.relationships.cards.length - 1;

  const onTimeout = () => {
    setActiveTab(activeTab === last ? 0 : activeTab + 1);
  };

  const onClick = (e) => {
    const id = parseInt(e.currentTarget.value);
    setActiveTab(id);
  };

  return (
    <div
      className={"flex flex-col-reverse md:flex-row justify-between  max-w-[1125px] mx-auto gap-3 md:gap-10"}
    >
      <TabsBar
        tabs={data.relationships.cards.map((c, index) => ({
          ...c,
          index: index,
        }))}
        activeTab={activeTab}
        onTimeout={onTimeout}
        onClick={onClick}
      />

      <div className="transition-opacity duration-500 ">
        {data.relationships.cards.map((t, index) => (
          <div
            className={
              index === activeTab
                ? " w-full md:w-[840px] opacity-100 --md:h-[500px] mx-auto transition-opacity duration-500"
                : " w-full md:w-[840px] opacity-0 hidden transition-opacity duration-500"
            }
            key={index}
          >
            <img
              src={t.relationships.image.localFile.publicURL}
              alt={t.image.alt}
              className=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TabsBar = ({ tabs, activeTab, onClick, onTimeout }) => {
  return (
    <div className="--flex -flex-col -justify-top md:flex-grow w-full mx-auto space-y-[9px] ">
      {tabs.map((t) => (
        <Tab
          title={t.title}
          index={t.index}
          active={activeTab === t.index}
          key={t.index}
          onTimeout={onTimeout}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

const Tab = ({ title, index, active, onTimeout, onClick, timeout = 5000 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const tick = () => {
      setProgress((prevProgress) => {
        const newProgress = Math.min(prevProgress + (50 / timeout) * 100, 100); // Ensure progress reaches 100%
        return newProgress;
      });
    };

    if (active) {
      const id = setInterval(tick, 50); // Update progress every 100ms
      return () => {
        setProgress(0);
        clearInterval(id);
      };
    }
    return () => {}; // Cleanup for inactive state
  }, [timeout, setProgress, active]);

  useEffect(() => {
    if (active) {
      const id = setInterval(onTimeout, timeout); // Update progress every 100ms
      return () => {
        setProgress(0);
        clearInterval(id);
      };
    }
    return () => {}; // Cleanup for inactive state
  }, [timeout, active, onTimeout]);

  return (
    <button
      value={index}
      className={
        "text-left block w-full border rounded-lg   text-[#3D434C] text-normal  leading-[24px] font-medium  overflow-hidden  " +
        (active
          ? " bg-[#ffffff] border-[#3D434C] hover:bg-[#F9FAFA]"
          : " bg-[#F9FAFA] border-[#DEE1E5] hover:bg-[#ffffff]")
      }
      onClick={onClick}
      style={
        active
          ? {
              boxShadow:
                " 0px 2px 4px -2px #1A1D210F, 0px 4px 8px -2px #1A1D2114",
            }
          : {}
      }
    >
      <div className=" flex flex-row justify-start items-center p-[15px]">
        <span className="w-6 h-6 mr-2.5 text-center  hidden md:inline-block">
          <img src={`/tab-${index + 1}.svg`} alt="bars" />
        </span>
        <span className="h-6">{title}</span>
      </div>

      <div
        className={" h-1  " + (active ? "bg-[#4A72FF] " : "--bg-[#e5ebf1] ")}
        style={{ width: active ? `${progress}%` : "100%" }}
      ></div>
    </button>
  );
};


export default ParagraphCardsContainerHpHero;
